<template>
    <!-- game section start  -->
    <section class="game-section pb-120">
        <div class="container-fluid">
            <div class="row align-items-center justify-content-between mb-lg-15 mb-md-8 mb-sm-6 mb-4">
                <div class="col-6">
                    <h2 class="display-four tcn-1 cursor-scale growUp">{{ t.swiper.topPlay }}</h2>
                </div>
            </div>
            <div class="row gy-lg-10 gy-6">
                <div class="col-xxl-2 col-lg-3 col-md-4 col-sm-6" v-for="game in allgames" :key="game.id">
                    <div class="game-card-wrapper mx-auto">
                        <div class="game-card mb-5 p-2">
                            <div class="game-card-border"></div>
                            <div class="game-card-border-overlay"></div>
                            <div class="game-img">
                                <img class="w-100 h-100" v-lazy="game.thumbnailUrl" alt="game">
                            </div>
                            <div class="game-link d-center">
                                <a @click="getFeed(game)" class="btn2 pointer">
                                    <i class="ti ti-arrow-right fs-2xl"></i>
                                </a>
                            </div>
                        </div>
                        <a @click="getFeed(game)" class="pointer">
                            <a @click="getFeed(game)">
                                <template v-if="titalOne">
                                    <h4 class="game-title mb-0 tcn-1 cursor-scale growDown2 title-anim">{{ game.title_en }}</h4>
                                </template>
                                <template v-if="titalTow">
                                    <h4 class="game-title mb-0 tcn-1 cursor-scale growDown2 title-anim">{{ game.title_ar }}</h4>
                                </template>
                                <template v-if="titalThree">
                                    <h4 class="game-title mb-0 tcn-1 cursor-scale growDown2 title-anim">{{ game.title_en }}</h4>
                                </template>
                            </a>
                            
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- game section end  -->
</template>

<script>
import { onMounted, ref } from 'vue';
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import { t, availableLanguages, setLanguage, currentLanguageCode } from '../../../locales/language'
export default {
    name: "AppGames",
    async setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie();
        const allgames = ref({});
        const titalOne = ref(false)
        const titalTow = ref(false)
        const titalThree = ref(false)

        onMounted(() => {
                if (localStorage.getItem('language') === '') {
                    titalOne.value =  true;
                } else if (localStorage.getItem('language') === 'AR') {
                    titalTow.value = true;
                } else {
                    titalThree.value = true;
                }
        })


        try {
            await HTTP.get(`GetMostViewed.php?LIMIT=48`).then((res) => {
            allgames.value = res.data.MostViewed; 
           });
        } catch (err) {
            console.log(err);
        }

        const getFeed = (game) => {
            cookie.setCookie('cat', game.category)
            router.push({ name: "details", params: { gid: game.gid } });
        };

        return {
           allgames,
           getFeed,
           t, availableLanguages, setLanguage, currentLanguageCode, titalOne, titalTow, titalThree
        }
    }
}
</script>

<style>

</style>