<template>
   <AppHeader />
   <main class="main-container container-fluid d-flex pt-20 px-0 position-relative">
    <app-sidebar />
    <article class="main-content mt-10">
      <Suspense>
          <template #default>
            <app-games />
          </template>
          <template #fallback>
              <app-loading />
          </template>
      </Suspense>
    <AppFooter/>
  </article>
</main>
</template>

<script>
import AppHeader from '@/components/layouts/AppHeader.vue'
import AppFooter from '@/components/layouts/AppFooter.vue'
import AppSidebar from '@/components/layouts/AppSidebar.vue'
import AppGames from '@/components/Pages/HomePage/AppGames.vue'
import AppLoading from '../../components/Spinners/AppLoading.vue'
import { Suspense } from "vue";
export default {
  name: "GamesView",
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
    AppGames,
    AppLoading,
    Suspense
  }
}
</script>

<style>

</style>
